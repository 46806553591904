import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { UploadedDocumentComponent } from '@shared/components/uploaded-document/uploaded-document.component';
import { UploadedFileResponse } from '@shared/models/uploaded-file.model';
import { map, take } from 'rxjs';
import { UploadedFileType } from '../../../modules/wizard/models/uploaded-file.type';
import { FileUploadService } from '../../services/file-upload.service';

@Component({
  selector: 'hmt-document-upload-category',
  templateUrl: './document-upload-category.component.html',
  styleUrls: ['./document-upload-category.component.scss'],
  standalone: true,
  imports: [CommonModule, UploadedDocumentComponent, NgOptimizedImage],
})
export class DocumentUploadCategoryComponent {
  @Input({ required: true }) categoryName!: string;
  @Input() viewOnly: boolean = false;
  @Input() files: UploadedFileType[] = [];
  @Input() otherOrdId: string = '';

  @Output() fileUploaded = new EventEmitter<{ category: string; file: UploadedFileType }>();
  @ViewChild('fileInput') fileInput!: ElementRef;
  private orgId: string;
  private paths: string[];

  constructor(
    private store: Store,
    private fileUploadService: FileUploadService
  ) {}

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  uploadFile(event: Event): void {
    try {
      const input = event.target as HTMLInputElement;
      const userName = this.store.selectSnapshot(state => state.auth.user.firstName + ' ' + state.auth.user.lastName);
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        const fileDetails: UploadedFileType = {
          fileName: file.name,
          fileType: file.type,
          customFileName: '',
          orgUserId: userName,
          uploadedDate: '',
          fileId: '',
          isActive: false,
          isUploaded: false,
          key: undefined,
        };
        this.orgId = this.store.selectSnapshot(state => state.auth.user.orgId);
        // this.store.dispatch(new AddUploadedFile(this.categoryName, fileDetails));

        const path = `frontend/testing`;
        this.paths = [path];

        const current_time = new Date();
        current_time.setHours(current_time.getHours() + 5);
        current_time.setMinutes(current_time.getMinutes() + 30);
        const current_time_iso = current_time.toISOString();

        // TODO: Fix state issues and file-handler issues
        const formatted_time = current_time_iso.split('T')[0] + ' ' + current_time_iso.split('T')[1].split('.')[0];
        this.fileUploadService
          .uploadSingleFile(this.otherOrdId ?? this.orgId, this.paths, file)
          .pipe(
            take(1),
            map((response: UploadedFileResponse) => {
              console.log('response', response);
              fileDetails.fileId = response.id;
              fileDetails.isUploaded = true;
              fileDetails.orgUserId = userName;
              fileDetails.uploadedDate = formatted_time;
              fileDetails.key = response.id;

              this.fileUploaded.emit({ category: this.categoryName, file: fileDetails });

              // this.store.dispatch(new AddUploadedFile(this.categoryName, fileDetails));
            })
          )
          .subscribe();
      }
    } catch (error) {
      console.log(error);
    }
  }

  setActiveFile(file: UploadedFileType): void {
    this.files = this.files.map(f => ({
      ...f,
      isActive: f.fileId === file.fileId,
    }));
  }
}
